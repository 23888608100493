import { createAction, props } from '@ngrx/store';

import { Company } from '@models/company';
import { User } from '@models/user';
import { BusinessUser } from '@models/business-user';
import { ImpersonateState } from './impersonate-reducer';

export const LOAD = '[Impersonate] Load';
export const UPDATE = '[Impersonate] Update';
export const IMPERSONATE = '[Impersonate] Impersonate';
export const STOP_IMPERSONATE = '[Impersonate] Stop Impersonate';

export type ApplicationType =  'apps' | 'expert'

export const load = createAction(LOAD);
export const update = createAction(UPDATE, props<Partial<ImpersonateState>>());
export const impersonate = createAction(IMPERSONATE, props<{ app: ApplicationType, company: Company | null, user: User | BusinessUser | null }>());
export const stopImpersonate = createAction(STOP_IMPERSONATE);
