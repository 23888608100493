import { createReducer, on, Action } from '@ngrx/store';
import * as ImpersonateActions from './impersonate-actions';

import { Impersonate } from '@models/impersonate';

export interface ImpersonateState {
  impersonate: Impersonate | null;
  isLoading: boolean;
}

export const initialImpersonationState: ImpersonateState = {
  impersonate: null,
  isLoading: false
};

const reducer = createReducer(
  initialImpersonationState,
  on(ImpersonateActions.update, (state, newState) => ({ ...state, ...newState })),
);

export function impersonationReducer(state: ImpersonateState | undefined, action: Action) {
  return reducer(state, action);
}
