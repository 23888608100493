import { Partner } from './partner';

export class BusinessUnit {
  constructor(
    public id?: number,
    public name?: string,
    public receiptEmail?: string,
    public partner?: Partner,
  ) {}

  static fromJson(json: any): BusinessUnit {
    return new BusinessUnit(
      json.id,
      json.name,
      json.receipt_email,
      json.partner ? Partner.fromJson(json.partner): null
    );
  }

  static toJson(businessUnit: BusinessUnit): any {
    return {
      id: businessUnit.id,
      name: businessUnit?.name
    };
  }
}
