import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { ApiService } from '../api.service';
import { careUserSelector } from '../store/care-user/care-user-selector';
import { CareUser } from '../models/care-user';
import { updateCareUser } from '../store/care-user/care-user-action';
import { Role } from '@core/enums/role';


@Injectable({
  providedIn: 'root'
})
export class CareUserGuard implements CanLoad {
  constructor(
    private store: Store,
    private apiService: ApiService,
    private router: Router,
    private snackbar: TiimeSnackbarService
  ) {}

  canLoad(): Observable<boolean> {
    return this.hasCareUserAndHasCareAcceptedRole();
  }

  private hasCareUserAndHasCareAcceptedRole(): Observable<boolean> {
    return this.hasCareUserInStore().pipe(
      switchMap((inStore: boolean) => (inStore ? of(inStore) : this.hasCareUserInApi())),
      switchMap((hasBusinessUser: boolean) => (hasBusinessUser ? this.hasCareUserAcceptedRole() : of(false)))
    );
  }

  private hasCareUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(careUserSelector),
      map((careUser: CareUser) => !!careUser),
      take(1)
    );
  }

  private hasCareUserInApi(): Observable<boolean> {
    return this.apiService.getCareUser().pipe(
      tap((careUser: CareUser) =>
        this.store.dispatch(updateCareUser({ careUser }))
      ),
      catchError(() => of(false)),
      switchMap(() => this.hasCareUserInStore())
    );
  }

  private hasCareUserAcceptedRole(): Observable<boolean> {
    return this.store.pipe(
      select(careUserSelector),
      map((careUser: CareUser) => {
        const isAccepted =  careUser.roles?.includes(Role.CARE);
        if (!isAccepted) {
          this.snackbar.open('Accès refusé', SnackbarConfig.error);
          this.router.navigate(['/signin']);
        }
        return isAccepted;
      }),
      take(1)
    );
  }
}
