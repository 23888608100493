import { BusinessUnit } from './business-unit';
import { CompanyStatus } from '../enums/company-status.enum';
import { CompanyBase } from './company-base';

export class CompanyListItem extends CompanyBase {
  constructor(
    id?: number,
    name?: string,
    businessUnits?: BusinessUnit[],
    status?: CompanyStatus,
    legalForm?: string
  ) {
    super(id, name, businessUnits, status, legalForm);
  }

  static fromJson(json: any): CompanyListItem {
    return new CompanyListItem(
      json.id,
      json.name,
      json.business_units
        ? json.business_units.map((businessUnitJson: any) =>
            BusinessUnit.fromJson(businessUnitJson)
          )
        : [],
      json.status,
      json.legal_form
    );
  }

  static toJson(company: CompanyListItem): any {
    return {
      id: company.id,
      name: company.name,
      business_units: company.businessUnits
        ? company.businessUnits.map((businessUnit: BusinessUnit) =>
            BusinessUnit.toJson(businessUnit)
          )
        : [],
      status: company.status,
      legal_form: company.legalForm
    };
  }
}
