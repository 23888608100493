import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TiimeAuthService } from 'tiime-auth';

import { AuthModule } from './auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { ApiInterceptor } from './api.interceptor';
import { careUserFeatureKey } from './store/care-user';
import { careUserReducer } from './store/care-user/care-user-reducer';
import { impersonateFeatureKey } from './store/impersonate';
import { impersonationReducer } from './store/impersonate/impersonate-reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { ImpersonateEffects } from './store/impersonate/impersonate-effects';

export function refreshAuthToken(
  tiimeAuthService: TiimeAuthService
): () => void {
  tiimeAuthService.scheduleRenewal();

  return () => {};
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    SharedModule,
    StoreModule.forRoot({
      [careUserFeatureKey]: careUserReducer,
      [impersonateFeatureKey]: impersonationReducer
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([ImpersonateEffects])
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: refreshAuthToken,
      deps: [TiimeAuthService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
