import { BusinessUnit } from './business-unit';
import { CompanyStatus } from '../enums/company-status.enum';
import { Entity } from './entity';

export abstract class CompanyBase implements Entity {
  get hasBusinessUnits(): boolean {
    return this.businessUnits.length > 0;
  }
  get isBilled(): boolean {
    return this.status === CompanyStatus.BilledClient;
  }
  get isFreemium(): boolean {
    return this.status === CompanyStatus.Freemium;
  }

  constructor(
    public id: number,
    public name: string,
    public businessUnits: BusinessUnit[],
    public status: CompanyStatus,
    public legalForm: string
  ) {}
}
