import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './core/auth/signin/signin.component';
import { AuthCallbackComponent } from './core/auth/auth-callback/auth-callback.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CareUserGuard } from './core/guards/care-user.guard';

const routes: Routes = [
  { path: '', redirectTo: 'care', pathMatch: 'full' },
  {
    path: 'care',
    loadChildren: () => import('./care/care.module').then(m => m.CareModule),
    canLoad: [AuthGuard, CareUserGuard]
  },
  { path: 'signin', component: SigninComponent },
  { path: 'auth-callback', component: AuthCallbackComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'corrected'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
