import { createReducer, on } from '@ngrx/store';

import * as CareUserActions from './care-user-action';
import { CareUser } from '../../models/care-user';

export type CareUserState = CareUser;

export const careUserReducer = createReducer(
  null,
  on(
    CareUserActions.updateCareUser,
    (state: CareUserState, payload: { careUser: CareUser }) => new CareUser(
      payload.careUser.id,
      payload.careUser.firstName,
      payload.careUser.lastName,
      payload.careUser.acronym,
      payload.careUser.color,
      payload.careUser.email,
      payload.careUser.roles
    )
  )
);
