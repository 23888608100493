import { BusinessUser } from './business-user';

export class Contributor {
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(
    public id?: number,
    public email?: string,
    public firstName?: string,
    public lastName?: string
  ) {}

  static fromBusinessUser(businessUser: BusinessUser): Contributor {
    return new Contributor(
      businessUser.id,
      businessUser.email,
      businessUser.firstName,
      businessUser.lastName
    );
  }

  static fromJson(json: any): Contributor {
    return new Contributor(json.id, json.email, json.firstname, json.lastname);
  }

  static toJson(contributor: Contributor): any {
    return {
      id: contributor.id,
      email: contributor.email
    };
  }
}
