export interface ImpersonateJson {
  name?: string;
  email?: string;
  partner_name?: string;
}

export class Impersonate {
  constructor(
    public name?: string,
    public email?: string,
    public partnerName?: string
  ) {}

  static fromJson(json: ImpersonateJson): Impersonate {
    return new Impersonate(
      json.name,
      json.email,
      json.partner_name,
    );
  }
}
