import { Entity } from './entity';

export abstract class UserBase implements Entity {
  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.lastName
      ? this.lastName
      : undefined;
  }

  protected constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public email?: string
  ) {}
}
