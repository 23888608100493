import { BusinessUnit } from './business-unit';
import { ApeCode } from './ape_code';
import { VatSystem } from './vat_system';
import { CompanyStatus } from '../enums/company-status.enum';
import { Contributor } from './contributor';
import { IncomeCategory } from './income_category';
import { Manager } from './manager';
import { CompanyAppsInfo } from './company-apps-info';
import { User } from './user';
import { CompanyBase } from './company-base';

export class Company extends CompanyBase {
  get isMultiManagers(): boolean {
    return this.managers && this.managers.length > 1;
  }

  constructor(
    id?: number,
    name?: string,
    businessUnits?: BusinessUnit[],
    status?: CompanyStatus,
    legalForm?: string,
    public acronym?: string,
    public logoId?: number,
    public color?: string,
    public apeCode?: ApeCode,
    public siret?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public user?: User,
    public contributors?: Contributor[],
    public shareCapital?: number,
    public vatSystem?: VatSystem,
    public incomeCategory?: IncomeCategory,
    public managers?: Manager[],
    public companyAppsInfo?: CompanyAppsInfo
  ) {
    super(id, name, businessUnits, status, legalForm);
  }

  static fromJson(json: any): Company {
    return new Company(
      json.id,
      json.name,
      json.business_units
        ? json.business_units.map((businessUnitJson: any) =>
            BusinessUnit.fromJson(businessUnitJson)
          )
        : [],
      json.status,
      json.legal_form,
      json.acronym,
      json.logo ? json.logo.id : null,
      json.color,
      json.ape_code && json.ape_code.id
        ? ApeCode.fromJson(json.ape_code)
        : null,
      json.siret,
      json.street,
      json.postal_code,
      json.city,
      json.user ? User.fromJson(json.user) : null,
      json.contributors
        ? json.contributors.map((contributorJson: any) =>
            Contributor.fromJson(contributorJson)
          )
        : [],
      json.share_capital,
      json.vat_system ? VatSystem.fromJson(json.vat_system) : null,
      json.income_category
        ? IncomeCategory.fromJson(json.income_category)
        : null,
      json.managers
        ? json.managers.map((managerJson: any) => Manager.fromJson(managerJson))
        : [],
      json.live_data ? CompanyAppsInfo.fromCompanyJson(json.live_data) : null
    );
  }

  static toJson(company: Company): any {
    return {
      id: company.id,
      legal_form: company.legalForm,
      name: company.name,
      ape_code: company.apeCode ? ApeCode.toJson(company.apeCode) : null,
      siret: company.siret,
      street: company.street,
      postal_code: company.postalCode,
      city: company.city,
      user: company.user ? User.toJson(company.user) : null,
      business_units: company.businessUnits
        ? company.businessUnits.map((businessUnit: BusinessUnit) =>
            BusinessUnit.toJson(businessUnit)
          )
        : [],
      contributors: company.contributors
        ? company.contributors.map((contributor: Contributor) =>
            Contributor.toJson(contributor)
          )
        : [],
      share_capital: company.shareCapital,
      vat_system: company.vatSystem
        ? VatSystem.toJson(company.vatSystem)
        : null,
      income_category: company.incomeCategory
        ? IncomeCategory.toJson(company.incomeCategory)
        : null
    };
  }
}
