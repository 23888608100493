export interface PartnerJson {
  id: number;
  name?: string;
}

export class Partner {
  constructor(public id: number, public name?: string) {}

  static fromJson(partner: PartnerJson): Partner {
    return new Partner(partner.id, partner.name);
  }

  static toJson(partner: Partner): PartnerJson {
    return {
      id: partner.id,
      name: partner.name,
    };
  }
}
