import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TiimeAuthService } from 'tiime-auth';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private authService: TiimeAuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService
      .parseHash()
      .pipe(
        tap(() => this.router.navigate(['/care'])),
        catchError(error => {
          this.router.navigate(['/signin']);
          return throwError(error);
        })
      )
      .subscribe();
  }
}
