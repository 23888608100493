<div class="signin-container">
  <form
    class="signin-form"
    [formGroup]="signInForm"
    (ngSubmit)="submitSignInForm()"
  >
    <img class="signin-form-illustration" src="assets/logo_tiime_care_signin.svg" />
    <div class="signin-form-separator"></div>
    <span class="signin-form-header">Connectez-vous</span>
    <div class="signin-form-content">
      <div class="input-container">
        <input type="text" placeholder="Adresse email" formControlName="email" />
        <label class="input-error-label" *ngIf="signInForm.emailErrorMessage">{{
          signInForm.emailErrorMessage
        }}</label>
      </div>
      <div class="input-container">
        <input
          type="password"
          placeholder="Mot de passe"
          formControlName="password"
        />
        <label
          class="input-error-label"
          *ngIf="signInForm.passwordErrorMessage"
          >{{ signInForm.passwordErrorMessage }}</label
        >
      </div>
      <t2-mat-button accent raised>Je me connecte</t2-mat-button>
    </div>
  </form>
</div>
