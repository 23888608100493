import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay } from 'rxjs/operators';

import { PaginationData, PaginationRange } from 'tiime-components';

import { ImpersonateRequest } from '@interfaces/impersonate-request.interface';
import { BusinessUnit } from '@models/business-unit';
import { ApeCode } from '@models/ape_code';
import { BusinessUser } from '@models/business-user';
import { CareUser } from '@models/care-user';
import { Company } from '@models/company';
import { Impersonate } from '@models/impersonate';
import { IncomeCategory } from '@models/income_category';
import { Partner } from '@models/partner';
import { User } from '@models/user';
import { VatSystem } from '@models/vat_system';

import { ApiAlertError } from '../core/decorators/api-alert-error';
import { HttpHelper } from './helpers/http.helper';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = 'api/v1/care';
  private apeCodesCache: Observable<ApeCode[]>;
  private incomeCategoriesCache: Observable<IncomeCategory[]>;
  private legalFormsCache: Observable<string[]>;
  private vatSystemsCache: Observable<VatSystem[]>;

  constructor(private http: HttpClient) {}

  @ApiAlertError()
  getCareUser(): Observable<CareUser> {
    const url = `${this.baseUrl}/users/me`;
    return this.http
      .get(url)
      .pipe(map(careUserJson => CareUser.fromJson(careUserJson)));
  }

  @ApiAlertError()
  getApeCodes(): Observable<ApeCode[]> {
    if (!this.apeCodesCache) {
      const url = `api/v1/ape_codes`;

      this.apeCodesCache = this.http.get<object[]>(url).pipe(
        map(apeCodesJson =>
          apeCodesJson.map(apeCodeJson => ApeCode.fromJson(apeCodeJson))
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.apeCodesCache;
  }

  @ApiAlertError()
  getIncomeCategories(): Observable<IncomeCategory[]> {
    if (!this.incomeCategoriesCache) {
      const url = 'api/v1/income_categories';

      this.incomeCategoriesCache = this.http.get<object[]>(url).pipe(
        map(incomeCategoriesJson =>
          incomeCategoriesJson.map(incomeCategoryJson =>
            IncomeCategory.fromJson(incomeCategoryJson)
          )
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.incomeCategoriesCache;
  }

  @ApiAlertError()
  getLegalForms(): Observable<string[]> {
    if (!this.legalFormsCache) {
      const url = `api/v1/legal_forms`;

      this.legalFormsCache = this.http.get<string[]>(url).pipe(
        map(legalForms =>
          legalForms.filter(legalForm => legalForm !== 'UserApp')
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.legalFormsCache;
  }

  @ApiAlertError()
  getVatSystems(): Observable<VatSystem[]> {
    if (!this.vatSystemsCache) {
      const url = `api/v1/expert/vat_systems`;

      this.vatSystemsCache = this.http.get<object[]>(url).pipe(
        map(vatSystemsJson =>
          vatSystemsJson.map(vatSystemJson => VatSystem.fromJson(vatSystemJson))
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    return this.vatSystemsCache;
  }

  @ApiAlertError()
  getBusinessUnits(
    searchTerms?: string,
    partnerId?: number
  ): Observable<BusinessUnit[]> {
    const url = `${this.baseUrl}/business_units`;
    let params = HttpHelper.setQParam(searchTerms);

    if (partnerId !== null && partnerId !== undefined) {
      params = params.set('partner', partnerId.toString());
    }

    return this.http
      .get<object[]>(url, { params, observe: 'response' })
      .pipe(
        map(response =>
          response.status === 204
            ? []
            : response.body.map(businessUnitJson =>
                BusinessUnit.fromJson(businessUnitJson)
              )
        )
      );
  }

  @ApiAlertError()
  searchBusinessUsers(
    searchTerms: string,
    range = new PaginationRange(0, 50)
  ): Observable<PaginationData<BusinessUser>> {
    const url = `${this.baseUrl}/business_users`;
    const params = HttpHelper.setQParam(searchTerms);
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    return this.http
      .get<object[]>(url, { params, headers, observe: 'response' })
      .pipe(
        filter(response => response.status !== 204),
        HttpHelper.mapToPaginationData(range, json =>
          BusinessUser.fromJson(json)
        )
      );
  }

  @ApiAlertError()
  deleteCompany(
    userId: number,
    companyId: number,
    confirmationControlValue: string
  ): Observable<any> {
    const url = `${this.baseUrl}/users/${userId}/companies/${companyId}`;
    const deleteOptionsBody = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        verify: confirmationControlValue
      }
    };
    return this.http.delete(url, deleteOptionsBody);
  }

  @ApiAlertError()
  deleteUser(
    userId: number,
    confirmationControlValue: string
  ): Observable<any> {
    const url = `${this.baseUrl}/users/${userId}`;
    const deleteOptionsBody = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        verify: confirmationControlValue
      }
    };
    return this.http.delete(url, deleteOptionsBody);
  }

  @ApiAlertError()
  getUsers(
    range: PaginationRange,
    searchTerms: string
  ): Observable<PaginationData<User>> {
    const url = `${this.baseUrl}/users`;

    const params = HttpHelper.setQParam(searchTerms);
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    const options = { params, headers };

    return this.http
      .get<object[]>(url, { ...options, observe: 'response' })
      .pipe(
        filter(response => response.status !== 204),
        HttpHelper.mapToPaginationData(range, json => User.fromJson(json))
      );
  }

  @ApiAlertError()
  getUser(userId: number): Observable<User> {
    const url = `${this.baseUrl}/users/${userId}`;
    return this.http.get(url).pipe(map(userJson => User.fromJson(userJson)));
  }

  @ApiAlertError()
  getPartners(
    searchTerms?: string,
    range?: PaginationRange
  ): Observable<PaginationData<Partner>> {
    const url = `${this.baseUrl}/partners`;
    const params = HttpHelper.setQParam(searchTerms);

    let headers = new HttpHeaders();
    if (range) {
      headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    }

    return this.http
      .get(url, { params, headers, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(range, json => Partner.fromJson(json))
      );
  }

  @ApiAlertError()
  updateUser(user: User): Observable<User> {
    const url = `${this.baseUrl}/users/${user.id}`;
    return this.http
      .patch(url, User.toJson(user))
      .pipe(map(userJson => User.fromJson(userJson)));
  }

  @ApiAlertError()
  getUserCompanies(userId: number): Observable<Company[]> {
    const url = `${this.baseUrl}/users/${userId}/companies`;
    return this.http
      .get<object[]>(url)
      .pipe(
        map(companiesJson =>
          companiesJson.map(companyJson => Company.fromJson(companyJson))
        )
      );
  }

  @ApiAlertError()
  updateCompany(company: Company): Observable<Company> {
    const url = `${this.baseUrl}/companies/${company.id}`;
    return this.http
      .patch(url, Company.toJson(company))
      .pipe(map(companyJson => Company.fromJson(companyJson)));
  }

  @ApiAlertError()
  getCompanyLogo(companyId: number, logoId: number): Observable<Blob> {
    const url = `${this.baseUrl}/companies/${companyId}/logos/${logoId}/preview`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError()
  getCareMembers(): Observable<User[]> {
    const url = `${this.baseUrl}/members`;
    return this.http
      .get<object[]>(url)
      .pipe(map(usersJson => usersJson.map(user => User.fromJson(user))));
  }

  @ApiAlertError()
  transferCompany(
    company: Company,
    destinationBusinessUnitId: number
  ): Observable<any> {
    const url = `${this.baseUrl}/companies/${company.id}/transfer_business_unit`;
    const body = {
      destination_business_unit_id: destinationBusinessUnitId
    };
    return this.http.post(url, body);
  }

  @ApiAlertError()
  postImpersonation(impersonation: ImpersonateRequest): Observable<any> {
    const url = `${this.baseUrl}/impersonations`;
    return this.http.post(url, impersonation);
  }

  @ApiAlertError()
  getCurrentImpersonate(): Observable<Impersonate | null> {
    const url = `${this.baseUrl}/users/me/impersonations`;

    return this.http.get(url)
      .pipe(
        map(json => Impersonate.fromJson(json)),
        catchError(() => of(null))
      );
  }

  @ApiAlertError([404])
  impersonateForUser(userId: number): Observable<Impersonate> {
    const url = `${this.baseUrl}/users/me/users/${userId}/impersonations`;

    return this.http
      .post(url, {})
      .pipe(map(json => Impersonate.fromJson(json)));
  }

  @ApiAlertError([404])
  impersonateAccountantForCompany(companyId: number): Observable<Impersonate> {
    const url = `${this.baseUrl}/users/me/companies/${companyId}/impersonations`;

    return this.http
      .post(url, {})
      .pipe(map(json => Impersonate.fromJson(json)));
  }

  @ApiAlertError()
  stopImpersonate(): Observable<any> {
    const url = `${this.baseUrl}/users/me/impersonations`;

    return this.http.delete(url)
  }
}
