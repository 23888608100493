import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import { TiimePipesModule } from 'tiime-utils';

import { MaterialModule } from './material/material.module';
import * as Directives from './directives';
import * as Components from './components';
import * as Pipes from './pipes';
import { setSharedInjector } from './shared-injector';

export function lottiePlayerFactory(): any {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    TiimePipesModule,
    LottieModule.forRoot({ player: lottiePlayerFactory, useCache: true })
  ],
  declarations: [
    Directives.VerticalEllipsisDirective,
    Components.SidenavComponent,
    Components.HeaderComponent,
    Components.EmptyStateSearchComponent,
    Components.SidenavItemComponent,
    Components.ConfirmDialogComponent,
    Pipes.BusinessUnitsNamesPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    TiimePipesModule,
    Directives.VerticalEllipsisDirective,
    Components.SidenavComponent,
    Components.HeaderComponent,
    Components.EmptyStateSearchComponent,
    Components.SidenavItemComponent,
    Components.ConfirmDialogComponent,
    Pipes.BusinessUnitsNamesPipe
  ]
})
export class SharedModule {
  constructor(injector: Injector) {
    setSharedInjector(injector);
  }
}
