import { UserBase } from './user-base';
import { Role } from '../enums/role';

export class CareUser extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    public acronym?: string,
    public color?: string,
    email?: string,
    public roles: Role[] = []
  ) {
    super(id, firstName, lastName, email);
  }

  static fromJson(json: any): CareUser {
    return new CareUser(
      json.id,
      json.firstname,
      json.lastname,
      json.acronym,
      json.color,
      json.email,
      json.roles
    );
  }

  hasRole(role: Role): boolean {
    return this.roles.includes(role);
  }

  hasAnyRole(roles: Role[]): boolean {
    return this.roles.some(role => roles.includes(role))
  }

  isLeader(): boolean {
    return this.hasRole(Role.CARE_LEADER)
  }

  canUseNewImpersonate(): boolean {
    return this.hasAnyRole([Role.CARE_EXPERT, Role.CARE_BUSINESS, Role.CARE_LEADER])
  }
}
