import { BusinessUnitUser } from './business-unit-user';

export class BusinessUser {
  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.lastName
      ? this.lastName
      : undefined;
  }

  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public phone?: string,
    public email?: string,
    public businessUnitsUser?: BusinessUnitUser[],
    public hasInternalAccess?: boolean,
    public color?: string,
    public acronym?: string,
    public hasTiimeBusiness?: boolean,
    public hasCriticalAccess?: boolean,
    public auth0Id?: string,
    public isPartnerAdmin?: boolean
  ) {}

  static fromJson(json: any): BusinessUser {
    return new BusinessUser(
      json.id,
      json.firstname,
      json.lastname,
      json.phone,
      json.email,
      json.business_unit_users
        ? json.business_unit_users.map((businessUnitUser: any) =>
            BusinessUnitUser.fromJson(businessUnitUser)
          )
        : [],
      json.has_internal_access,
      json.color,
      json.acronym,
      json.has_tiime_business,
      json.has_critical_access,
      json.auth0_id,
      json.is_partner_admin
    );
  }

  static toJson(businessUser: BusinessUser): any {
    return {
      id: businessUser.id,
      firstname: businessUser.firstName,
      lastname: businessUser.lastName,
      phone: businessUser.phone,
      email: businessUser.email
    };
  }
}
