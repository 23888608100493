import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const excludedUrls = [];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith('api/')) {
      const url = this.replaceApi(request.url);

      request = request.clone({
        url,
        setHeaders: {
          'tiime-app': 'tiime-care',
          'tiime-app-version': environment.VERSION
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.router.navigate(['signin']);
        } else if (
          error instanceof HttpErrorResponse &&
          error.status === 403 &&
          !excludedUrls.some(excludedUrl => error.url.includes(excludedUrl))
        ) {
          this.router.navigate(['unauthorized']);
        }
        return throwError(error);
      })
    );
  }

  private replaceApi(url: string): string {
    return `${environment.API_URL}/${url.replace('api/', '')}`;
  }
}
