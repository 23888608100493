export class CompanyAppsInfo {
  constructor(
    public totalReceipts?: number,
    public matchedReceipts?: number,
    public totalInvoices?: number,
    public paidInvoices?: number,
    public savedInvoices?: number,
    public draftInvoices?: number,
    public cancelledInvoices?: number,
    public matchedInvoices?: number,
    public totalQuotations?: number,
    public totalBankConnections?: number,
    public lastPushDate?: string,
    public hasAppsAccess?: boolean
  ) {}

  static fromCompanyJson(json: any): CompanyAppsInfo {
    return new CompanyAppsInfo(
      json.total_receipts,
      json.matched_receipts,
      json.total_invoices,
      json.paid_invoices,
      json.saved_invoices,
      json.draft_invoices,
      json.cancelled_invoices,
      json.matched_invoices,
      json.total_quotations,
      json.total_bank_connections,
      json.last_push_date,
      json.has_apps_access
    );
  }
}
