import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { ApiService } from '@core/api.service';

import * as ImpersonateActions from './impersonate-actions';
import { ApplicationType } from './impersonate-actions';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { User } from '@models/user';
import { Company } from '@models/company';
import { Impersonate } from '@models/impersonate';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ImpersonateEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private apiService: ApiService
  ) {}

  loadAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImpersonateActions.load),
      switchMap(() =>
        this.apiService
          .getCurrentImpersonate()
          .pipe(map(impersonate => ImpersonateActions.update({ impersonate })))
      )
    )
  );

  impersonateActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImpersonateActions.impersonate),
      filter(({ company, user }) => !!company || !!user),
      tap(() =>
        this.store.dispatch(ImpersonateActions.update({ isLoading: true }))
      ),
      switchMap(({ app, company, user }) =>
        this.impersonate(app, user, company).pipe(
          map(impersonate =>
            ImpersonateActions.update({ isLoading: false, impersonate })
          ),
          catchError(error => {
            this.store.dispatch(
              ImpersonateActions.update({ isLoading: false })
            );
            return throwError(error);
          })
        )
      )
    )
  );

  stopImpersonateActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImpersonateActions.stopImpersonate),
      tap(() =>
        this.store.dispatch(ImpersonateActions.update({ isLoading: true }))
      ),
      switchMap(() =>
        this.apiService.stopImpersonate().pipe(
          map(() =>
            ImpersonateActions.update({ isLoading: false, impersonate: null })
          ),
          catchError(error => {
            this.store.dispatch(
              ImpersonateActions.update({ isLoading: false })
            );
            return throwError(error);
          })
        )
      )
    )
  );

  private impersonate(
    appType: ApplicationType,
    user: User | null,
    company: Company | null
  ): Observable<Impersonate> {
    const obs = user
      ? this.apiService.impersonateForUser(user.id)
      : this.apiService.impersonateAccountantForCompany(company.id);

    const baseUrl =
      appType === 'apps' ? environment.APPS_URL : environment.EXPERT_URL;
    const suffix = company
      ? `/companies/${company.id}${
          appType === 'apps' ? '/account/transactions' : ''
        }`
      : '';

    return obs.pipe(tap(() => window.open(`${baseUrl}${suffix}`, '_blank')));
  }
}
