import { BusinessUnit } from './business-unit';

export class BusinessUnitUser {
  constructor(
    public id?: number,
    public businessUnit?: BusinessUnit,
    public isAdmin?: boolean
  ) {}

  static fromJson(json: any): BusinessUnitUser {
    return new BusinessUnitUser(
      json.id,
      json.business_unit ? BusinessUnit.fromJson(json.business_unit) : null,
      json.is_admin
    );
  }

  static toJson(businessUnitUser: BusinessUnitUser): any {
    return {
      id: businessUnitUser.id
    };
  }
}
