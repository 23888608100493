import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-components';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';
import { TiimeAuthService } from 'tiime-auth';

import { SignInForm } from './signin-form';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signInForm: SignInForm;

  constructor(
    private authService: TiimeAuthService,
    private snackbar: TiimeSnackbarService
  ) {}

  ngOnInit(): void {
    this.initSignInForm();
  }

  @MarkFormAsTouchedIfInvalid('signInForm')
  submitSignInForm(): void {
    this.login();
  }

  private initSignInForm(): void {
    this.signInForm = new SignInForm();
  }

  private login(): void {
    this.authService
      .login(this.signInForm.email.value, this.signInForm.password.value)
      .pipe(
        catchError(error => {
          this.loginError();
          return throwError(error);
        })
      )
      .subscribe();
  }

  private loginError(): void {
    this.snackbar.open(
      'Identifiant ou mot de passe invalide',
      SnackbarConfig.error
    );
  }
}
