export class Manager {
  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : this.lastName
      ? this.lastName
      : undefined;
  }

  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public receiptEmail?: string
  ) {}

  static fromJson(json: any): Manager {
    return new Manager(json.id, json.firstname, json.lastname, json.receipt_email);
  }
}
