import { NgModule } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS
} from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  TiimeButtonModule,
  TiimePaginatorModule,
  TiimeTableModule,
  TiimeSearchBarModule,
  TiimeTagModule,
  TiimeIconModule,
  TiimeSnackbarModule,
  TiimeSubheaderModule,
  TiimeEmptyStateModule,
  TiimeMenuModule,
  TiimeDialogModule,
  TiimeInputContainerModule,
  TiimeTwocolorsIconModule,
  TiimeTooltipModule,
  TiimeSortModule,
  TiimeFilterSearchModule,
  TiimeOverlayModule,
  TIIME_DATE_FORMATS,
  TIIME_RIPPLE_GLOBAL_OPTIONS
} from 'tiime-components';

@NgModule({
  imports: [MomentDateModule],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: TIIME_RIPPLE_GLOBAL_OPTIONS
    },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ],
  exports: [
    MatAutocompleteModule,
    MatMenuModule,
    MatRadioModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    TextFieldModule,
    TiimeButtonModule,
    TiimePaginatorModule,
    TiimeTableModule,
    TiimeSearchBarModule,
    TiimeTagModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeSubheaderModule,
    TiimeEmptyStateModule,
    TiimeMenuModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeTwocolorsIconModule,
    TiimeTooltipModule,
    TiimeSortModule,
    TiimeFilterSearchModule,
    TiimeOverlayModule
  ]
})
export class MaterialModule {}
