import { CompanyListItem } from './company-list-item';
import { UserBase } from './user-base';

export class User extends UserBase {
  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    public phone?: string,
    email?: string,
    public companies?: CompanyListItem[],
    public auth0Id?: string,
    public passwordUpdatedAt?: string,
    public hasTiimeBusiness?: boolean,
    public hasCriticalAccess?: boolean,
  ) {
    super(id, firstName, lastName, email);
  }

  static fromJson(json: any): User {
    return new User(
      json.id,
      json.firstname,
      json.lastname,
      json.phone,
      json.email,
      json.companies
        ? json.companies.map((companyJson: object) =>
            CompanyListItem.fromJson(companyJson)
          )
        : [],
      json.auth0_id,
      json.password_updated_at,
      json.has_tiime_business,
      json.has_critical_access
    );
  }

  static toJson(user: User): any {
    return {
      id: user.id,
      firstname: user.firstName,
      lastname: user.lastName,
      phone: user.phone ? user.phone : null,
      email: user.email
    };
  }
}
